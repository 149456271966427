import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";  
import axios from "axios";  
  
const API_BASE = process.env.REACT_APP_API_BASE;  
  
// Define the type for the payload including donation ID and data  
interface softDeleteDonationPayload {  
  donationId: number;
}  
  
// Async thunk for editing a donation  
export const softDeleteDonation = createAsyncThunk(  
  "editDonation",  
  async ({ donationId }: softDeleteDonationPayload) => {  
    const { data } = await axios.get(`${API_BASE}/donations/movetobin/${donationId}`, {  
      withCredentials: true,  
    });  
    return data;  
  }  
);  
  
const softDeleteDonationSlice = createSlice({  
  name: "softDeleteDonation",  
  initialState: {  
    loading: false,  
    donation: null as any,  
    error: false,  
    errorData: null as any,  
  },  
  reducers: {},  
  extraReducers: (builder) => {  
    builder  
      .addCase(softDeleteDonation.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(softDeleteDonation.fulfilled, (state, action) => {  
        state.loading = false;  
        state.donation = action.payload; // Updated donation data  
      })  
      .addCase(softDeleteDonation.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.error; // Store the actual error object  
      });  
  },  
});  
  
export default softDeleteDonationSlice.reducer;  
