import React, { useEffect } from "react";
import layoutTwo from "../../components/layout/layoutTwo";
import DonersTableBody from "../../components/Doner/DonersTableBody";
import { useAppDispatch } from "../../redux/hooks";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { listDoners } from "../../redux/slices/doner/listDonersSlice"; // Redux slice for fetching doners
import { useSelector } from "react-redux";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const DonersDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { donersData } = useSelector((state: any) => state.listDoners);

  useEffect(() => {
    dispatch(listDoners());
  }, []);

  return (
    <div className="dashboard">
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ pb: 2 }}>
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb component="a" href="/doners" label="Doners" />
        </Breadcrumbs>
      </div>
      <DonersTableBody />
    </div>
  );
};

export default layoutTwo(DonersDashboard);