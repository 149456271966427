// src/redux/donationData/getUserSlice.ts

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE = process.env.REACT_APP_API_BASE;

export const getUser = createAsyncThunk("getUser", async () => {

  try {
    const { data } = await axios.get(`${API_BASE}/me`, {  
      withCredentials: true, // This ensures cookies are sent with the request  
    }); 
    return data;
  } catch (error: any) {
    throw error.response?.data.statusCode;
  }
});

const getUserSlice = createSlice({
  name: "getUser",
  initialState: {
    loading: false,
    user: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getUserSlice.reducer;
