import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/hooks";
import { getDonationById } from "../redux/slices/getDonationByIDSlice";
import { editDonation } from "../redux/slices/editDonationByIDSlice";
import { getDonationOptions } from "../redux/slices/options/getDonationOptionsSlice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Autocomplete,
  Container,
} from "@mui/material";
import layoutTwo from "../components/layout/layoutTwo";

// Define the shape of the donation data
interface DonationData {
  sr_no: number;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  cash_chq_receive_date: string;
  amount: string;
  payment_mode: string;
  purpose: string;
  event: string;
  tr_no: string;
  head: string;
  pan: string;
  email: string;
  whatsapp_group: string;
  cash_deposit_date: string;
  gift: string;
  note: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

const EditDonation = () => {
  const { id } = useParams(); // Get the donation ID from URL
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [numericAmount, setNumericAmount] = useState<number | null>(null);

  const { donationDetails, loading } = useSelector(
    (state: any) => state.getDonationDetails
  );
  const { options, loadingOptions } = useSelector(
    (state: any) => state.getDonationOptions
  );

  const [donationData, setDonationData] = useState<DonationData>({
    sr_no: 0,
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    cash_chq_receive_date: "",
    amount: "",
    payment_mode: "",
    purpose: "",
    event: "",
    tr_no: "",
    head: "",
    pan: "",
    email: "",
    whatsapp_group: "",
    cash_deposit_date: "",
    gift: "",
    note: "",
  });

  const [paymentMode, setPaymentMode] = useState(""); // Track the selected payment mode
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (id) {
      dispatch(getDonationById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getDonationOptions());
  }, []);

  useEffect(() => {
    if (donationDetails) {
      setDonationData(donationDetails); // Set initial form data with fetched donation data
      setPaymentMode(donationDetails.payment_mode); // Set initial payment mode
    }
  }, [donationDetails]);

  const handleKeyDown = (e: any) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "+",
    ];
    const input = e.target as HTMLInputElement;

    if (
      input.value.length === 0 &&
      (e.key < "1" || e.key > "9") &&
      e.key !== "+"
    ) {
      e.preventDefault();
      return;
    }

    if (input.value.length >= 15 && !allowedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    }

    if (!allowedKeys.includes(e.key) && (e.key < "0" || e.key > "9")) {
      e.preventDefault(); // Block all other keys
    }
  };

  const handleChange = (e: any, input: keyof DonationData) => {
    const value = e.target.value;

    if (input === "phone") {
      let sanitizedValue = value.replace(/[^0-9+]/g, "");
      if (sanitizedValue.indexOf("+") > 0) {
        sanitizedValue = sanitizedValue.replace("+", "");
      }
      if (sanitizedValue.startsWith("0")) {
        sanitizedValue = sanitizedValue.substring(1);
      }
      sanitizedValue = sanitizedValue.substring(0, 15);

      setDonationData((prevData) => ({
        ...prevData,
        [input]: sanitizedValue,
      }));
      return;
    }

    setDonationData((prevData) => ({
      ...prevData,
      [input]: value,
    }));
  };

  const dateHandler = (e: Dayjs | null, input: keyof DonationData) => {
    const selectedDate = e ? e.toISOString() : dayjs().toISOString();
    setDonationData((prevData) => ({
      ...prevData,
      [input]: selectedDate,
      cash_deposit_date:
        paymentMode === "Online" && input === "cash_chq_receive_date"
          ? selectedDate
          : input === "cash_deposit_date"
          ? selectedDate
          : prevData.cash_deposit_date,
    }));
  };

  // const autoHandler = (e: any, value: any, input: keyof DonationData) => {
  //   const selectedValue = value && typeof value === "string" ? value : "";

  //   setDonationData((prevData) => ({
  //     ...prevData,
  //     [input]: selectedValue,
  //   }));

  //   if (input === "payment_mode" && value === "Online") {
  //     setPaymentMode(value);
  //     setDonationData((prevData) => ({
  //       ...prevData,
  //       cash_deposit_date:
  //         prevData.cash_chq_receive_date || dayjs().format("YYYY-MM-DD"),
  //     }));
  //   }
  // };

  const autoHandler = (e: any, value: any, input: keyof DonationData) => {
    const selectedValue = value && typeof value === "string" ? value : "";

    setDonationData((prevData) => ({
      ...prevData,
      [input]: selectedValue,
    }));

    if (input === "payment_mode") {
      setPaymentMode(selectedValue); // Update payment mode

      // Reset deposit date if payment mode is not "Online"

      setDonationData((prevData) => ({
        ...prevData,
        cash_deposit_date: donationData.cash_chq_receive_date, // Allow user to set deposit date manually
      }));
    }
  };

  const validateData = (): boolean => {
    let tempErrors: { [key: string]: string } = {};
    let isValid = true;

    // Name validation: Mandatory field
    if (!donationData.name.trim()) {
      isValid = false;
      tempErrors.name = "Name is required.";
    }

    const phoneRegex = /^\+?[1-9][0-9]{9,14}$/;
    if (!donationData.phone.trim()) {
      isValid = false;
      tempErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(donationData.phone)) {
      isValid = false;
      tempErrors.phone =
        "Invalid phone number. It must be 10-15 digits, and the first digit cannot be 0.";
    }

    // Amount validation: Mandatory field
    if (!String(donationData.amount).trim()) {
      isValid = false;
      tempErrors.amount = "Amount is required.";
    } else if (!/^\d+(\.\d{1,2})?$/.test(String(donationData.amount).trim())) {
      isValid = false;
      tempErrors.amount = "Invalid amount format.";
    } else {
      // Store the numeric value in a separate state variable
      setNumericAmount(parseFloat(String(donationData.amount).trim()));
    }

    if (!donationData.cash_chq_receive_date) {
      isValid = false;
      tempErrors.cash_chq_receive_date = "Transaction date is required.";
    }

    if (donationData.pincode.trim() && !/^\d{6}$/.test(donationData.pincode)) {
      isValid = false;
      tempErrors.pincode = "Invalid pincode format.";
    }

    if (
      donationData.pan.trim() &&
      !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(donationData.pan)
    ) {
      isValid = false;
      tempErrors.pan = "Invalid PAN number format.";
    }

    if (
      donationData.email.trim() &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        donationData.email
      )
    ) {
      isValid = false;
      tempErrors.email = "Invalid email format.";
    }

    if (donationData.tr_no.trim() && !/^\d+$/.test(donationData.tr_no)) {
      isValid = false;
      tempErrors.tr_no = "Tax Receipt No. must contain only numbers.";
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isFormDataValid = validateData();

    if (isFormDataValid && id) {
      const updatedData = {
        ...donationData,
        cash_chq_receive_date:
          donationData.cash_chq_receive_date || dayjs().toISOString(),
        cash_deposit_date:
          donationData.cash_deposit_date || dayjs().toISOString(),
      };

      dispatch(editDonation({ donationId: id, donationData: updatedData }));
      navigate("/donations");
    } else {
      console.log("Validation Errors:", errors);
    }
  };

  return (
    <div className="user-edit">
      <div role="presentation" onClick={() => navigate("/donations")}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/donations"
            label="Donations"
            onClick={() => navigate("/donations")}
          />
          <StyledBreadcrumb label="Edit" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {loading || loadingOptions ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StyledPaper variant="outlined">
            <Box
              component="form"
              sx={{ flexGrow: 1 }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
                sx={{ pb: 2 }}
              >
                <Grid xs={12} sm={12}>
                  <Typography variant="h4" gutterBottom>
                    Edit Donation
                  </Typography>
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Sr. No."
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    disabled
                    value={donationData.sr_no}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
              >
                <Grid xs={12} sm={6}>
                  <TextField
                    label="Name"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name}
                    value={donationData.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    value={donationData.phone}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleChange(e, "phone")}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={
                        donationData.cash_chq_receive_date
                          ? dayjs(donationData.cash_chq_receive_date)
                          : null
                      }
                      label="Transaction Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          required: true,
                          error: !!errors.cash_chq_receive_date,
                          helperText: errors.cash_chq_receive_date,
                        },
                      }}
                      onChange={(e) => dateHandler(e, "cash_chq_receive_date")}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Amount"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    value={donationData.amount}
                    error={!!errors.amount}
                    helperText={errors.amount}
                    onChange={(e) => handleChange(e, "amount")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="payment-mode-autocomplete"
                    options={paymentModes}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donationData.payment_mode}
                    onChange={(e, value) =>
                      autoHandler(e, value, "payment_mode")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Mode"
                        size="small"
                        required
                        error={!!errors.payment_mode}
                        helperText={errors.payment_mode}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.purpose.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donationData.purpose}
                    onChange={(e, value) => autoHandler(e, value, "purpose")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purpose"
                        size="small"
                        error={!!errors.purpose}
                        helperText={errors.purpose}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Address"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donationData.address}
                    error={!!errors.address}
                    helperText={errors.address}
                    onChange={(e) => handleChange(e, "address")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="City"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donationData.city}
                    error={!!errors.city}
                    helperText={errors.city}
                    onChange={(e) => handleChange(e, "city")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="state-autocomplete"
                    options={states}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donationData.state}
                    onChange={(e, value) => autoHandler(e, value, "state")}
                    renderInput={(params) => (
                      <TextField {...params} label="State" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Pincode"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donationData.pincode}
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                    onChange={(e) => handleChange(e, "pincode")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="PAN"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donationData.pan}
                    error={!!errors.pan}
                    helperText={errors.pan}
                    onChange={(e) => handleChange(e, "pan")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.events.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donationData.event}
                    onChange={(e, value) => autoHandler(e, value, "event")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Event"
                        size="small"
                        error={!!errors.event}
                        helperText={errors.event}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Tax Receipt No."
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.tr_no}
                    helperText={errors.tr_no}
                    onChange={(e) => handleChange(e, "tr_no")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.heads.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donationData.head}
                    onChange={(e, value) => autoHandler(e, value, "head")}
                    renderInput={(params) => (
                      <TextField {...params} label="Head" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donationData.email}
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={
                        donationData.cash_deposit_date
                          ? dayjs(donationData.cash_deposit_date)
                          : null
                      }
                      label="Deposit Date"
                      disabled={paymentMode === "Online"} // Enable if the payment mode is anything but "Online"
                      slotProps={{
                        textField: {
                          size: "small",
                          error: !!errors.cash_deposit_date,
                          helperText: errors.cash_deposit_date,
                        },
                      }}
                      onChange={(e) => dateHandler(e, "cash_deposit_date")}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.gifts?.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donationData.gift}
                    onChange={(e, value) => autoHandler(e, value, "gift")}
                    renderInput={(params) => (
                      <TextField {...params} label="Gift" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Note"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donationData.note}
                    error={!!errors.note}
                    helperText={errors.note}
                    onChange={(e) => handleChange(e, "note")}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: 200, mt: 2 }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}
      </Container>
    </div>
  );
};

export default layoutTwo(EditDonation);

const paymentModes = ["Cash", "Cheque", "Online"];

const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];
