import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { restoreDonation } from "../../redux/slices/donation/restoreDonationByIDSlice";
import { binDonationData } from "../../redux/slices/donation/binDonationDataSlice"; // Slice to fetch soft-deleted records

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const SoftDeletedDonations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { binData, loading } = useSelector((state: any) => state.binDonationData);

  const [data, setData] = useState<any>([]);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [selectedDonationId, setSelectedDonationId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(binDonationData()); // Fetch soft deleted records on mount
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(binData)) {
      const formattedData = binData?.map((donation: any) => ({
        id: donation.sr_no,
        name: donation.name,
        phone: donation.phone,
        address: donation.address,
        city: donation.city,
        state: donation.state,
        pincode: donation.pincode,
        txnDate: donation.cash_chq_receive_date,
        amount: donation.amount,
        paymentMode: donation.payment_mode,
        purpose: donation.purpose,
        event: donation.event,
        trNo: donation.tr_no,
        head: donation.head,
        pan: donation.pan,
        email: donation.email,
        depositDate: donation.cash_deposit_date,
        gift: donation.gift,
        note: donation.note,
        whatsappGroup: donation.whatsapp_group,
      }));
      setData(formattedData);
    } else {
      setData([]); // Set an empty array if binData is not valid
    }
  }, [binData]);

  const handleRestoreClick = (id: number) => {
    setSelectedDonationId(id); // Set the ID for the donation to restore
    setRestoreDialogOpen(true); // Open the restore confirmation dialog
  };

  const confirmRestore = () => {
    if (selectedDonationId) {
      dispatch(restoreDonation(selectedDonationId)); // Call the restore action
      setRestoreDialogOpen(false);
      refreshData();
    }
  };

  const refreshData = () => {
    dispatch(binDonationData()); // Re-fetch the soft-deleted donation records
  };

  const handleBackClick = () => {
    navigate("/donations"); // Navigate back to donations list
  };

  const columns: any = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      renderHeader: () => <p style={headerTextStyle}>Sr. No.</p>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderHeader: () => <p style={headerTextStyle}>Name</p>,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Phone</p>,
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      renderHeader: () => <p style={headerTextStyle}>Address</p>,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>City</p>,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>State</p>,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Pincode</p>,
    },
    {
      field: "txnDate",
      headerName: "Txn Date",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Txn Date</p>,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Amount</p>,
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Payment Mode</p>,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Purpose</p>,
    },
    {
      field: "event",
      headerName: "Event",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Event</p>,
    },
    {
      field: "trNo",
      headerName: "TR No.",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>TR No.</p>,
    },
    {
      field: "head",
      headerName: "Head",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Head</p>,
    },
    {
      field: "pan",
      headerName: "PAN",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>PAN</p>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Email</p>,
    },
    {
      field: "depositDate",
      headerName: "Deposit Date",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Deposit Date</p>,
    },
    {
      field: "gift",
      headerName: "Gift",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Gift</p>,
    },
    {
      field: "note",
      headerName: "Note",
      width: 250,
      renderHeader: () => <p style={headerTextStyle}>Note</p>,
    },
    {
      field: "whatsappGroup",
      headerName: "Whatsapp Group",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Whatsapp Group</p>,
    },
    {
      field: "actions",
      type: "actions",
      width: 60, // Adjusted width to fit both buttons
      renderCell: (params: any) => (
        <IconButton aria-label="restore" onClick={() => handleRestoreClick(params.row.id)}>
          <Icon icon="mdi:restore" />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="table">
      {/* Button Container with Back and Refresh Buttons */}
      <div className="button" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Button
          variant="text"
          startIcon={<Icon icon="lucide:arrow-left" className="icons" />}
          onClick={handleBackClick} // Back button
        >
          Donation Bin
        </Button>
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          startIcon={<Icon icon="lucide:refresh-cw" className="icons" />}
          onClick={refreshData} // Trigger data refresh
        >
          Refresh
        </Button>
      </div>

      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        <DataGridPro
          rows={data}
          columns={columns}
          loading={loading}
          autoHeight
          slots={{ toolbar: GridToolbar }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          checkboxSelection
          initialState={{
            pinnedColumns: { left: ["name"], right: ["actions"] },
          }}
        />
      </Box>

      {/* Restore Confirmation Dialog */}
      <Dialog open={restoreDialogOpen} onClose={() => setRestoreDialogOpen(false)}>
        <DialogTitle>Confirm Restore</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to restore this donation record?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRestoreDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmRestore} color="primary" variant="contained">
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SoftDeletedDonations;