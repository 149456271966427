// src/redux/donationData/alldonationData.ts

import getDonationData from "./getDonationDataSlice";
import addDonationSlice from "./addDonationSlice";
import getDonationByIDSlice from "./getDonationByIDSlice";
import editDonationByIDSlice from "./editDonationByIDSlice";
import softDeleteDonationByIDSlice from "./donation/softDeleteDonationByIDSlice";
import restoreDonationByIDSlice from "./donation/restoreDonationByIDSlice";
import binDonationDataSlice from "./donation/binDonationDataSlice";
import getExpenseDataSlice from "./getExpenseDataSlice";
import addExpenseSlice from "./addExpenseSlice";
import getExpenseByIDSlice from "./getExpenseByIDSlice";
import editExpenseByIDSlice from "./editExpenseByIDSlice";
import softDeleteExpenseByIDSlice from "./expense/softDeleteExpenseByIDSlice";
import restoreExpenseByIDSlice from "./expense/restoreExpenseByIDSlice";
import binExpenseDataSlice from "./expense/binExpenseDataSlice";
import getUserSlice from "./getUserSlice";
import getDonationOptionsSlice from "./options/getDonationOptionsSlice";
import updateDonationEventOptionsSlice from "./options/updateDonationEventOptionsSlice";
import updateDonationGiftOptionsSlice from "./options/updateDonationGiftOptionsSlice";
import updateDonationHeadOptionsSlice from "./options/updateDonationHeadOptionsSlice";
import updateDonationPurposeOptionsSlice from "./options/updateDonationPurposeOptionsSlice";
import listDonersSlice from "./doner/listDonersSlice"
import getDonerByIdSlice from "./doner/getDonerByIdSlice";
import addDonerSlice from "./doner/addDonerSlice";
import updateDonerSlice from "./doner/updateDonerSlice";
import deleteDonerSlice from "./doner/deleteDonerSlice";
import searchDonerByPhoneSlice from "./doner/searchDonerByPhoneSlice";
import redirectSlice from "./redirectSlice";

export const allSlices = {
  getDonations: getDonationData,
  addDonation: addDonationSlice,
  getDonationDetails: getDonationByIDSlice,
  editDonation: editDonationByIDSlice,
  softDeleteDonation: softDeleteDonationByIDSlice,
  restoreDonation: restoreDonationByIDSlice,
  binDonationData: binDonationDataSlice,
  getExpense: getExpenseByIDSlice,
  editExpense: editExpenseByIDSlice,
  getExpenses: getExpenseDataSlice,
  addExpense: addExpenseSlice,
  softDeleteExpense: softDeleteExpenseByIDSlice,
  restoreExpense: restoreExpenseByIDSlice,
  binExpenseData: binExpenseDataSlice,
  getUser: getUserSlice,
  getDonationOptions: getDonationOptionsSlice,
  updateDonationEventOptions: updateDonationEventOptionsSlice,
  updateDonationGiftOptions: updateDonationGiftOptionsSlice,
  updateDonationHeadOptions: updateDonationHeadOptionsSlice,
  updateDonationPurposeOptions: updateDonationPurposeOptionsSlice,
  listDoners: listDonersSlice,
  getDonerById: getDonerByIdSlice,
  addDoner: addDonerSlice,
  updateDoner: updateDonerSlice,
  deleteDoner: deleteDonerSlice,
  searchDonerByPhone: searchDonerByPhoneSlice,
  redirect: redirectSlice,
};
