import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface DonerData {
  name: string;
  email: string;
  phone: string;
}

interface RejectedValue {
  statusCode: number;
  message: string;
}

const API_BASE = process.env.REACT_APP_API_BASE;

// Async thunk for adding a new doner
export const addDoner = createAsyncThunk<any, DonerData, { rejectValue: RejectedValue }>(
  "addDoner",
  async (donerData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${API_BASE}/doners`, donerData, { withCredentials: true });
      return data;
    } catch (error: any) {
      if (error.response) {
        alert(`Error: ${error.response.data.error}`);
        return rejectWithValue({
          statusCode: error.response.status,
          message: error.message
        });
      } else {
        return rejectWithValue({
          statusCode: 500,
          message: "An unexpected error occurred",
        });
      }
    }
  }
);

const addDonerSlice = createSlice({
  name: "addDoner",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addDoner.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(addDoner.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(addDoner.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.errorData = action.payload;
      });
  },
});

export default addDonerSlice.reducer;