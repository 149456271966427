import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";  
import axios from "axios";  
  
const API_BASE = process.env.REACT_APP_API_BASE;  
  
// Define the type for the payload including expense ID and data  
interface restoreExpensePayload {  
  expenseId: number;
}  
  
// Async thunk for editing a expense  
export const restoreExpense = createAsyncThunk(  
  "editExpense",  
  async ({ expenseId }: restoreExpensePayload) => {  
    const { data } = await axios.get(`${API_BASE}/expenses/restore/${expenseId}`, {  
      withCredentials: true,  
    });  
    return data;  
  }  
);  
  
const restoreExpenseSlice = createSlice({  
  name: "restoreExpense",  
  initialState: {  
    loading: false,  
    expense: null as any,  
    error: false,  
    errorData: null as any,  
  },  
  reducers: {},  
  extraReducers: (builder) => {  
    builder  
      .addCase(restoreExpense.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(restoreExpense.fulfilled, (state, action) => {  
        state.loading = false;  
        state.expense = action.payload; // Updated expense data  
      })  
      .addCase(restoreExpense.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.error; // Store the actual error object  
      });  
  },  
});  
  
export default restoreExpenseSlice.reducer;  
