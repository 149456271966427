import React, { useEffect } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import SoftDeletedExpenses from "../components/Expenses/SoftDeletedExpenses";
import { useAppDispatch } from "../redux/hooks";
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard">
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{pb: 2}}>
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb component="a" href="/expenses" label="Expenses" />
          <StyledBreadcrumb component="a" href="/expenses/bin" label="Bin"/>
        </Breadcrumbs>
      </div>
      <SoftDeletedExpenses />
    </div>
  );
};

export default layoutTwo(Dashboard);
