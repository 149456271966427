import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { restoreExpense } from "../../redux/slices/expense/restoreExpenseByIDSlice"; // Assuming you have a restore slice for expenses
import { binExpenseData } from "../../redux/slices/expense/binExpenseDataSlice"; // Assuming you have a slice to fetch soft-deleted expenses

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const SoftDeletedExpenses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { binData, loading } = useSelector((state: any) => state.binExpenseData); // Adjust according to your slice name

  const [data, setData] = useState<any>([]);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(binExpenseData()); // Fetch soft deleted expenses on mount
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(binData)) {
      const formattedData = binData.map((expense: any) => ({
        id: expense.sr_no,
        by: expense.by,
        date: expense.date,
        amount: expense.amount,
        purpose: expense.purpose,
        event: expense.event,
        head: expense.head,
        note: expense.note,
      }));
      setData(formattedData);
    } else {
      setData([]); // Set an empty array if binData is not valid
    }
  }, [binData]);

  const handleRestoreClick = (id: number) => {
    setSelectedExpenseId(id); // Set the ID for the expense to restore
    setRestoreDialogOpen(true); // Open the restore confirmation dialog
  };

  const confirmRestore = () => {
    if (selectedExpenseId) {
      dispatch(restoreExpense({ expenseId: selectedExpenseId })); // Call the restore action for expense
      setRestoreDialogOpen(false); // Close the dialog after restoring
      refreshData();
    }
  };

  const refreshData = () => {
    dispatch(binExpenseData()); // Re-fetch the soft-deleted expense records
  };

  const handleBackClick = () => {
    navigate("/expenses"); // Navigate back to expenses list
  };

  const columns: any = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      renderHeader: () => <p style={headerTextStyle}>Sr. No.</p>,
    },
    {
      field: "by",
      headerName: "By",
      width: 170,
      renderHeader: () => <p style={headerTextStyle}>By</p>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Date</p>,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderHeader: () => <p style={headerTextStyle}>Amount</p>,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Purpose</p>,
    },
    {
      field: "event",
      headerName: "Event",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Event</p>,
    },
    {
      field: "head",
      headerName: "Head",
      width: 200,
      renderHeader: () => <p style={headerTextStyle}>Head</p>,
    },
    {
      field: "note",
      headerName: "Note",
      width: 250,
      renderHeader: () => <p style={headerTextStyle}>Note</p>,
    },
    {
      field: "actions",
      type: "actions",
      width: 60, // Adjusted width to fit both buttons
      renderCell: (params: any) => (
        <IconButton aria-label="restore" onClick={() => handleRestoreClick(params.row.id)}>
          <Icon icon="mdi:restore" />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="table">
      {/* Button Container with Back and Refresh Buttons */}
      <div className="button" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Button
          variant="text"
          startIcon={<Icon icon="lucide:arrow-left" className="icons" />}
          onClick={handleBackClick} // Back button
        >
          Expenses Bin
        </Button>
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          startIcon={<Icon icon="lucide:refresh-cw" className="icons" />}
          onClick={refreshData} // Trigger data refresh
        >
          Refresh
        </Button>
      </div>

      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        <DataGridPro
          rows={data}
          columns={columns}
          loading={loading}
          autoHeight
          slots={{ toolbar: GridToolbar }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          checkboxSelection
          initialState={{
            pinnedColumns: { left: ["by"], right: ["actions"] },
          }}
        />
      </Box>

      {/* Restore Confirmation Dialog */}
      <Dialog open={restoreDialogOpen} onClose={() => setRestoreDialogOpen(false)}>
        <DialogTitle>Confirm Restore</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to restore this expense record?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRestoreDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmRestore} color="primary" variant="contained">
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SoftDeletedExpenses;