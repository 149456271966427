import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the type for the payload
interface SearchDonerByPhonePayload {
  phone: string;
}

// Define the interface for the rejected value
interface RejectedValue {
  statusCode: number;
  message: string;
}

const API_BASE = process.env.REACT_APP_API_BASE;

// Async thunk for searching a doner by phone
export const searchDonerByPhone = createAsyncThunk<
  any, // Response type
  SearchDonerByPhonePayload, // Input type
  { rejectValue: RejectedValue } // Reject type
>(
  "searchDonerByPhone",
  async ({ phone }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_BASE}/search/doner/phone`, {
        params: { phone }, // Send phone number as query parameter
        withCredentials: true,
      });
      return data; // Successfully fetch and return the doner data
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue({
          statusCode: error.response.status,
          message: error.response.data.message || error.message,
        });
      } else {
        return rejectWithValue({
          statusCode: 500,
          message: "An unexpected error occurred",
        });
      }
    }
  }
);

// Create the slice for searching doner by phone
const searchDonerByPhoneSlice = createSlice({
  name: "searchDonerByPhone",
  initialState: {
    loading: false,
    doner: null as any, // This will store the doner details
    error: false,
    errorData: null as any, // This will store error details
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchDonerByPhone.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.doner = null;
        state.errorData = null;
      })
      .addCase(searchDonerByPhone.fulfilled, (state, action) => {
        state.loading = false;
        state.doner = action.payload; // Store the fetched doner data
      })
      .addCase(searchDonerByPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorData = action.payload; // Store the actual error payload
      });
  },
});

export default searchDonerByPhoneSlice.reducer;