import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";  
import axios from "axios";  
  
// Define an interface for the rejected value  
interface RejectedValue {  
  statusCode: number;  
  message: string;  
}  
  
const API_BASE = process.env.REACT_APP_API_BASE;  
  
// Async thunk for fetching an expense by ID  
export const getExpenseById = createAsyncThunk<any, string, { rejectValue: RejectedValue }>(  
  "getExpenseById",  
  async (expenseId, { rejectWithValue }) => {  
    try {  
      const { data } = await axios.get(`${API_BASE}/expenses/${expenseId}`, { withCredentials: true });  
      return data;  
    } catch (error: any) {  
      if (error.response) {  
        return rejectWithValue({  
          statusCode: error.response.status,  
          message: error.message  
        });  
      } else {  
        return rejectWithValue({  
          statusCode: 500,  
          message: "An unexpected error occurred"  
        });  
      }  
    }  
  }  
);  
  
const getExpenseByIdSlice = createSlice({  
  name: "getExpenseById",  
  initialState: {  
    loading: false,  
    expenseDetails: null as any,  
    error: false,  
    errorData: null as any,  
  },  
  reducers: {},  
  extraReducers: (builder) => {  
    builder  
      .addCase(getExpenseById.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(getExpenseById.fulfilled, (state, action) => {  
        state.loading = false;  
        state.expenseDetails = action.payload;  
      })  
      .addCase(getExpenseById.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.payload; // Store the actual error payload  
      });  
  },  
});  
  
export default getExpenseByIdSlice.reducer;  
