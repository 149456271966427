import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface RejectedValue {
  statusCode: number;
  message: string;
}

const API_BASE = process.env.REACT_APP_API_BASE;

// Async thunk for deleting a doner by ID
export const deleteDoner = createAsyncThunk<any, string, { rejectValue: RejectedValue }>(
  "deleteDoner",
  async (donerId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`${API_BASE}/doners/${donerId}`, { withCredentials: true });
      return data;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue({
          statusCode: error.response.status,
          message: error.message,
        });
      } else {
        return rejectWithValue({
          statusCode: 500,
          message: "An unexpected error occurred",
        });
      }
    }
  }
);

const deleteDonerSlice = createSlice({
  name: "deleteDoner",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteDoner.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDoner.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteDoner.rejected, (state, action) => {
         state.loading = false;
         state.error = true;
         state.errorData = action.payload; // Store the actual error object
       });
   },
 });
 
 export default deleteDonerSlice.reducer;