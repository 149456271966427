import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";  
import axios from "axios";  
  
const API_BASE = process.env.REACT_APP_API_BASE;  
  
// Define the type for the payload including expense ID and data  
interface EditExpensePayload {  
  expenseId: string;  
  expenseData: any;  
}  
  
// Async thunk for editing an expense  
export const editExpense = createAsyncThunk(  
  "editExpense",  
  async ({ expenseId, expenseData }: EditExpensePayload) => {  
    const { data } = await axios.put(`${API_BASE}/expenses/${expenseId}`, expenseData, {  
      withCredentials: true,  
    });  
    return data;  
  }  
);  
  
const editExpenseSlice = createSlice({  
  name: "editExpense",  
  initialState: {  
    loading: false,  
    expense: null as any,  
    error: false,  
    errorData: null as any,  
  },  
  reducers: {},  
  extraReducers: (builder) => {  
    builder  
      .addCase(editExpense.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(editExpense.fulfilled, (state, action) => {  
        state.loading = false;  
        state.expense = action.payload; // Updated expense data  
      })  
      .addCase(editExpense.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.error; // Store the actual error object  
      });  
  },  
});  
  
export default editExpenseSlice.reducer;  
