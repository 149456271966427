import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";  
import axios from "axios";  
  
const API_BASE = process.env.REACT_APP_API_BASE;  
  
// Define the type for the payload including donation ID and data  
interface restoreDonationPayload {  
  donationId: number;
}  

interface RejectedValue {  
  statusCode: number;  
  message: string;  
} 
  
// // Async thunk for editing a donation  
// export const restoreDonation = createAsyncThunk(  
//   "restoreDonation",  
//   async ({ donationId }: restoreDonationPayload) => {  
//     const { data } = await axios.put(`${API_BASE}/donations/restore/${donationId}`, {  
//       withCredentials: true,  
//     });  
//     return data;  
//   }  
// ); 

export const restoreDonation = createAsyncThunk<any, number, { rejectValue: RejectedValue }>(
  "restoreDonation",
  async (donationId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_BASE}/donations/restore/${donationId}`, {
        withCredentials: true,
      });
      return data;
    } catch (error: any) {
      console.log("Error Response:", error.response); // Add this line to see the full error response
      if (error.response) {
        return rejectWithValue({
          statusCode: error.response.status,
          message: error.message,
        });
      } else {
        return rejectWithValue({
          statusCode: 500,
          message: "An unexpected error occurred",
        });
      }
    }
  }
);
  
const restoreDonationSlice = createSlice({  
  name: "restoreDonation",  
  initialState: {  
    loading: false,  
    donation: null as any,  
    error: false,  
    errorData: null as any,  
  },  
  reducers: {},  
  extraReducers: (builder) => {  
    builder  
      .addCase(restoreDonation.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(restoreDonation.fulfilled, (state, action) => {  
        state.loading = false;  
        state.donation = action.payload; // Updated donation data  
      })  
      .addCase(restoreDonation.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.error; // Store the actual error object  
      });  
  },  
});  
  
export default restoreDonationSlice.reducer;  
