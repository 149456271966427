import React, { useEffect, useState } from "react";
import layoutTwo from "../../components/layout/layoutTwo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { addDoner } from "../../redux/slices/doner/addDonerSlice";

import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Backdrop,
  Autocomplete,
  Box,
  Typography,
  Paper,
  Container,
} from "@mui/material";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

interface DonerData {
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  email: string;
  note: string;
  doner_type: string;
  pan: string;
  whatsapp_group: string;
}

interface Errors {
  [key: string]: string;
}

const NewDoner = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { success, loading } = useSelector((state: any) => state.addDoner);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (success && click) navigate("/doners");
  }, [success]);

  const [data, setData] = useState<DonerData>({
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    email: "",
    note: "",
    doner_type: "",
    pan: "",
    whatsapp_group: "",
  });

  const [errors, setErrors] = useState<Errors>({});

  const textHandler = (e: any, input: keyof DonerData) => {
    let newValue = e.target.value;

    if (input === "phone") {
      newValue = newValue.replace(/[^0-9+]/g, "");
    } else if (input === "pan") {
      newValue = newValue.toUpperCase(); // Automatically capitalize PAN input
    }

    setData((prevData) => ({
      ...prevData,
      [input]: newValue,
    }));
  };

  const validateData = (): boolean => {
    let tempErrors: Errors = {};
    let isValid = true;

    if (!data.name.trim()) {
      isValid = false;
      tempErrors.name = "Name is required.";
    }

    const phoneRegex = /^\+?[1-9][0-9]{9,14}$/;
    if (!data.phone.trim()) {
      isValid = false;
      tempErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(data.phone)) {
      isValid = false;
      tempErrors.phone = "Invalid phone number.";
    }

    if (
      data.email.trim() &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)
    ) {
      isValid = false;
      tempErrors.email = "Invalid email format.";
    }

    if (data.pan.trim() && !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(data.pan)) {
      isValid = false;
      tempErrors.pan = "Invalid PAN number format.";
    }

    setErrors(tempErrors);
    return isValid;
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateData()) {
      dispatch(addDoner(data));
      setClick(true);
    } else {
      alert("There are errors in the form. Please fix them before submitting.");
    }
  };

  return (
    <div className="user-create">
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/doners"
            label="Doners"
            onClick={() => navigate("/doners")}
          />
          <StyledBreadcrumb label="New" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        <StyledPaper variant="outlined">
          <Box
            component="form"
            sx={{ flexGrow: 1 }}
            noValidate
            autoComplete="off"
            onSubmit={submitHandler}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 2, sm: 4, md: 4 }}
            >
              <Grid xs={12} sm={12}>
                <Typography variant="h4" gutterBottom>
                  Add New Doner
                </Typography>
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  label="Name"
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={(e) => textHandler(e, "name")}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  label="Phone"
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone}
                  onChange={(e) => textHandler(e, "phone")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="Address"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address}
                  onChange={(e) => textHandler(e, "address")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="City"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city}
                  onChange={(e) => textHandler(e, "city")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="state-autocomplete"
                  options={states}
                  clearOnEscape
                  disableClearable
                  autoHighlight
                  onChange={(e: any, value: any) =>
                    setData((prevData) => ({
                      ...prevData,
                      state: value as string,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="State" size="small" />
                  )}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="Pincode"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.pincode}
                  helperText={errors.pincode}
                  onChange={(e) => textHandler(e, "pincode")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="Email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={(e) => textHandler(e, "email")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="PAN"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.pan}
                  helperText={errors.pan}
                  onChange={(e) => textHandler(e, "pan")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="doner_type-autocomplete"
                  options={donerTypes}
                  clearOnEscape
                  disableClearable
                  autoHighlight
                  onChange={(e: any, value: any) =>
                    setData((prevData) => ({
                      ...prevData,
                      doner_type: value as string,
                    }))
                  }
                  renderInput={(params) => (
                     <TextField {...params} label="Doner Type" size="small" />
                   )}
                 />
               </Grid>
               <Grid xs={12} md={6}>
                 <TextField
                   label="Whatsapp Group"
                   size="small"
                   variant="outlined"
                   fullWidth
                   error={!!errors.whatsapp_group}
                   helperText={errors.whatsapp_group}
                   onChange={(e) => textHandler(e, "whatsapp_group")}
                 />
               </Grid>
               <Grid xs={12} md={12}>
                 <TextField
                   label="Note"
                   size="small"
                   variant="outlined"
                   fullWidth
                   error={!!errors.note}
                   helperText={errors.note}
                   onChange={(e) => textHandler(e, "note")}
                 />
               </Grid>
               <Grid xs={12} md={12}>
                 <Button
                   variant="contained"
                   type="submit"
                   sx={{ width: 200, mt: 2 }}
                 >
                   Add Doner
                 </Button>
               </Grid>
             </Grid>
           </Box>
         </StyledPaper>
       </Container>
 
       {/* Backdrop with CircularProgress */}
       <Backdrop
         sx={{
           color: "#fff",
           zIndex: (theme) => theme.zIndex.drawer + 1,
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
         }}
         open={loading}
       >
         <CircularProgress color="inherit" />
       </Backdrop>
     </div>
   );
 };
 
 export default layoutTwo(NewDoner);
 
 const donerTypes = ["Individual", "Company", "Trust", "NGO", "Other"];
 
 const states = [
   "Andhra Pradesh",
   "Arunachal Pradesh",
   "Assam",
   "Bihar",
   "Chhattisgarh",
   "Goa",
   "Gujarat",
   "Haryana",
   "Himachal Pradesh",
   "Jharkhand",
   "Karnataka",
   "Kerala",
   "Madhya Pradesh",
   "Maharashtra",
   "Manipur",
   "Meghalaya",
   "Mizoram",
   "Nagaland",
   "Odisha",
   "Punjab",
   "Rajasthan",
   "Sikkim",
   "Tamil Nadu",
   "Telangana",
   "Tripura",
   "Uttarakhand",
   "Uttar Pradesh",
   "West Bengal",
   "Andaman and Nicobar Islands",
   "Chandigarh",
   "Dadra and Nagar Haveli and Daman and Diu",
   "Delhi",
   "Jammu and Kashmir",
   "Ladakh",
   "Lakshadweep",
   "Puducherry",
 ];