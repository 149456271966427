import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface DonerData {
  name: string;
  email: string;
  phone: string;
}

interface RejectedValue {
  statusCode: number;
  message: string;
}

const API_BASE = process.env.REACT_APP_API_BASE;

// Async thunk for updating a doner by ID
export const updateDoner = createAsyncThunk<any, { donerId: string, donerData: DonerData }, { rejectValue: RejectedValue }>(
  "updateDoner",
  async ({ donerId, donerData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`${API_BASE}/doners/${donerId}`, donerData, { withCredentials: true });
      return data;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue({
          statusCode: error.response.status,
          message: error.message,
        });
      } else {
        return rejectWithValue({
          statusCode: 500,
          message: "An unexpected error occurred",
        });
      }
    }
  }
);

const updateDonerSlice = createSlice({
  name: "updateDoner",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDoner.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDoner.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(updateDoner.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorData = action.payload;
      });
  },
});

export default updateDonerSlice.reducer;