import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface RejectedValue {
  statusCode: number;
  message: string;
}

const API_BASE = process.env.REACT_APP_API_BASE;

// Async thunk for fetching a doner by ID
export const getDonerById = createAsyncThunk<any, string, { rejectValue: RejectedValue }>(
  "getDonerById",
  async (donerId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_BASE}/doners/${donerId}`, { withCredentials: true });
      return data;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue({
          statusCode: error.response.status,
          message: error.message,
        });
      } else {
        return rejectWithValue({
          statusCode: 500,
          message: "An unexpected error occurred",
        });
      }
    }
  }
);

const getDonerByIdSlice = createSlice({
  name: "getDonerById",
  initialState: {
    loading: false,
    donerDetails: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDonerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDonerById.fulfilled, (state, action) => {
        state.loading = false;
        state.donerDetails = action.payload;
      })
      .addCase(getDonerById.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorData = action.payload;
      });
  },
});

export default getDonerByIdSlice.reducer;