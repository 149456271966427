// src/pages/EditDoner.tsx

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/hooks";
import { getDonerById } from "../../redux/slices/doner/getDonerByIdSlice";
import { updateDoner } from "../../redux/slices/doner/updateDonerSlice";
import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Autocomplete,
  Container,
} from "@mui/material";
import layoutTwo from "../../components/layout/layoutTwo";

interface DonerData {
  id: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  email: string;
  note: string;
  doner_type: string;
  pan: string;
  whatsapp_group: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

const EditDoner = () => {
  const { id } = useParams(); // Get the doner ID from URL
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { donerDetails, loading } = useSelector(
    (state: any) => state.getDonerById
  );
  const [donerData, setDonerData] = useState<DonerData>({
    id: "",
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    email: "",
    note: "",
    doner_type: "",
    pan: "",
    whatsapp_group: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (id) {
      dispatch(getDonerById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (donerDetails) {
      setDonerData(donerDetails);
    }
  }, [donerDetails]);

  const handleChange = (e: any, input: keyof DonerData) => {
    const value = e.target.value;

    setDonerData((prevData) => ({
      ...prevData,
      [input]: value,
    }));
  };
   
  const textHandler = (e: any, input: keyof DonerData) => {
   let newValue = e.target.value;

   if (input === "pan") {
     newValue = newValue.toUpperCase(); // Automatically capitalize PAN input
   }

   setDonerData((prevData) => ({
     ...prevData,
     [input]: newValue,
   }));
 };

  const validateData = (): boolean => {
    let tempErrors: { [key: string]: string } = {};
    let isValid = true;

    if (!donerData.name.trim()) {
      isValid = false;
      tempErrors.name = "Name is required.";
    }

    if (
      donerData.email.trim() &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(donerData.email)
    ) {
      isValid = false;
      tempErrors.email = "Invalid email format.";
    }

    if (
      donerData.pan.trim() &&
      !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(donerData.pan)
    ) {
      isValid = false;
      tempErrors.pan = "Invalid PAN number format.";
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isFormDataValid = validateData();

    if (isFormDataValid && id) {
      dispatch(updateDoner({ donerId: id, donerData }));
      navigate("/doners");
    } else {
      console.log("Validation Errors:", errors);
    }
  };

  return (
    <div className="user-edit">
      <div role="presentation" onClick={() => navigate("/doners")}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/doners"
            label="Doners"
            onClick={() => navigate("/doners")}
          />
          <StyledBreadcrumb label="Edit" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StyledPaper variant="outlined">
            <Box
              component="form"
              sx={{ flexGrow: 1 }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
              >
                <Grid xs={12} sm={12}>
                  <Typography variant="h4" gutterBottom>
                    Edit Doner
                  </Typography>
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Doner ID"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    disabled
                    value={donerData.id} // ID shown but not editable
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    size="small"
                    variant="outlined"
                    fullWidth
                    disabled // Phone is not editable
                    value={donerData.phone}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Name"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name}
                    value={donerData.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Address"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.address}
                    error={!!errors.address}
                    helperText={errors.address}
                    onChange={(e) => handleChange(e, "address")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="City"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.city}
                    error={!!errors.city}
                    helperText={errors.city}
                    onChange={(e) => handleChange(e, "city")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="state-autocomplete"
                    options={states}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donerData.state}
                    onChange={(e, value) =>
                      setDonerData((prevData) => ({
                        ...prevData,
                        state: value as string,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="State" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Pincode"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.pincode}
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                    onChange={(e) => handleChange(e, "pincode")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.email}
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="PAN"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.pan}
                    error={!!errors.pan}
                    helperText={errors.pan}
                    onChange={(e) => textHandler(e, "pan")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="doner-type-autocomplete"
                    options={donerTypes}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={donerData.doner_type}
                    onChange={(e, value) =>
                      setDonerData((prevData) => ({
                        ...prevData,
                        doner_type: value as string,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Doner Type" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Whatsapp Group"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.whatsapp_group}
                    error={!!errors.whatsapp_group}
                    helperText={errors.whatsapp_group}
                    onChange={(e) => handleChange(e, "whatsapp_group")}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Note"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={donerData.note}
                    error={!!errors.note}
                    helperText={errors.note}
                    onChange={(e) => handleChange(e, "note")}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: 200, mt: 2 }}
                  >
                    Update Doner
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}
      </Container>
    </div>
  );
};

export default layoutTwo(EditDoner);

const donerTypes = ["Individual", "Company", "Trust", "NGO", "Other"];

const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];
