import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDonationOptions } from "../redux/slices/options/getDonationOptionsSlice";
import { updateDonationEventOptions } from "../redux/slices/options/updateDonationEventOptionsSlice";
import { emphasize, styled } from "@mui/material/styles";
import {
  Breadcrumbs,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { Icon } from "@iconify/react";
import layoutTwo from "../components/layout/layoutTwo";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const EventOptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { options, loadingOptions: optionsLoading } = useSelector(
    (state: any) => state.getDonationOptions
  );
  const {
    loading: updateLoading,
    error: updateError,
    errorData: updateErrorData,
    optionsUpdate,
  } = useSelector((state: any) => state.updateDonationGiftOptions);
  const [giftOptions, setGiftOptions] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newGift, setNewGift] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    dispatch(getDonationOptions());
  }, [dispatch]);

  useEffect(() => {
    if (options && options.events && Array.isArray(options.events.options)) {
      setGiftOptions(options.events.options);
    } else {
      setGiftOptions([]);
    }
  }, [options]);

  useEffect(() => {
    if (optionsUpdate) {
      //setGiftOptions(options.gifts.options);
      setSnackbarMessage("Event options updated successfully.");
      setSnackbarOpen(true);
    }
  }, [optionsUpdate, options?.gifts?.options]);

  const handleAddGift = () => {
    const updatedGifts = [...giftOptions, newGift];
    dispatch(updateDonationEventOptions({ options: updatedGifts }));
    setGiftOptions(updatedGifts);
    setOpenDialog(false);
    setNewGift("");
  };

  const handleRemoveGift = (giftToRemove: string) => {
    const updatedGifts = giftOptions.filter((gift) => gift !== giftToRemove);
    dispatch(updateDonationEventOptions({ options: updatedGifts }));
    setGiftOptions(updatedGifts);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="dashboard">
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb component="a" href="/options" label="Options" />
          <StyledBreadcrumb component="a" href="/options/event" label="Event" />
        </Breadcrumbs>
      </div>
      {optionsLoading || updateLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Event Options</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {giftOptions.map((gift, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {gift}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleRemoveGift(gift)}
                      color="error"
                    >
                      <Icon icon="ic:outline-delete" className="icons" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            startIcon={<Icon icon="lucide:circle-plus" className="icons" />}
            onClick={handleOpenDialog}
          >
            Add Event Option
          </Button>
        </div>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Event Option</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Event Option"
            type="text"
            fullWidth
            variant="outlined"
            value={newGift}
            onChange={(e) => setNewGift(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddGift}>Add</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={updateError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default layoutTwo(EventOptions);
