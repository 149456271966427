// src/redux/donation/binDonationDataSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define an interface for the rejected value  
interface RejectedValue {  
  statusCode: number;  
  message: string;  
}  


const API_BASE = process.env.REACT_APP_API_BASE;

export const binDonationData = createAsyncThunk<any, void, { rejectValue: RejectedValue }>(  
  "binDonationData",  
  async (_, { rejectWithValue }) => {  
    try {  
      const { data } = await axios.get(`${API_BASE}/donations/bin`, { withCredentials: true });  
      return data;  
    } catch (error: any) {  
      // Check if the error structure is as expected  
      if (error.response) {  
        return rejectWithValue({  
          statusCode: error.response.status,  
          message: error.message  
        });  
      } else {  
        // Handle unexpected error structure  
        return rejectWithValue({  
          statusCode: 500,  
          message: "An unexpected error occurred"  
        });  
      }  
    }  
  }  
);  

const binDonationDataSlice = createSlice({
  name: "binDonationData",
  initialState: {
    loading: false,
    binData: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {  
    builder  
      .addCase(binDonationData.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(binDonationData.fulfilled, (state, action) => {  
        state.loading = false;  
        state.binData = action.payload;  
      })  
      .addCase(binDonationData.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.payload; // Store the actual error payload  
      });  
  },  
  
});

export default binDonationDataSlice.reducer;
