import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUser } from "../redux/slices/getUserSlice";
import {
  Button,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";

// Styling for the main container
const MainContainer = styled(Paper)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: "linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)", // Gradient background
  width: "100%", // Ensure it covers full width
  margin: 0, // Remove default margins
  padding: 0, // Remove default padding
  borderRadius: 0, // Eliminate border radius for full screen usage
});

// Paper component for form area
const FormPaper = styled(Paper)({
  padding: "40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
});

// Custom styled button
const StyledButton = styled(Button)({
  backgroundColor: "#ffffff",
  color: "#5F6368",
  textTransform: "none",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)",
  "&:hover": {
    backgroundColor: "#f8f9fa",
  },
  "& .MuiButton-startIcon": {
    marginRight: 8,
    color: "#4285F4",
  },
});

const Signin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current URL
  const { user, loading } = useSelector((state: any) => state.getUser);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  // Extract the error parameter from the URL and handle multiple error messages
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Correctly extract query parameters from the location
    const errorParam = queryParams.get("error");

    if (errorParam) {
      console.log("Error parameter found:", errorParam); // Debugging statement to ensure the error param is captured
    }

    if (errorParam === "email_not_allowed") {
      setErrorMessage("Your email is not allowed to sign in.");
      setShowError(true);
    } else if (errorParam === "account_deactivated") {
      setErrorMessage("Your account has been deactivated.");
      setShowError(true);
    }

    // Hide the error after 3 seconds
    if (errorParam) {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timeout on unmount
    }
  }, [location.search]);

  const createLoginUrl = (): string => {
    const currentUrl = window.location.href;
    const redirectUrl = encodeURIComponent(currentUrl);
    const loginBaseUrl = `${process.env.REACT_APP_API_BASE}/signin`;
    return `${loginBaseUrl}?redirectUrl=${redirectUrl}`;
  };

  return (
    <MainContainer>
      <FormPaper elevation={3}>
        <Typography component="h1" variant="h4" color="#173161" gutterBottom>
          Iskcon Bhisma
        </Typography>
        <Typography component="p" sx={{ mb: 2 }} color="grey.700">
          Sign in to your account
        </Typography>
        <Box mt={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <StyledButton
              variant="contained"
              fullWidth
              href={createLoginUrl()}
              startIcon={<Icon icon="logos:google-icon" />}
            >
              Sign in with Google
            </StyledButton>
          )}
        </Box>
      </FormPaper>

      {/* Snackbar for error message */}
      <Snackbar
        open={showError}
        onClose={() => setShowError(false)} // Close the Snackbar on manual action
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Adjust position if needed
        sx={{
          zIndex: 9999, // Ensure it's above other elements
        }}
      >
        <Alert
          onClose={() => setShowError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </MainContainer>
  );
};

export default Signin;