// src/redux/donationData/getUserSlice.ts

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE = process.env.REACT_APP_API_BASE;

export const getDonationOptions = createAsyncThunk("getDonationOptions", async () => {

  try {
    const { data } = await axios.get(`${API_BASE}/donation/options`, {  
      withCredentials: true, // This ensures cookies are sent with the request  
    }); 
    return data;
  } catch (error: any) {
    throw error.response?.data.statusCode;
  }
});

const getDonationOptionsSlice = createSlice({
  name: "getDonationOptions",
  initialState: {
    loadingOptions: false,
    options: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDonationOptions.pending, (state, action) => {
      state.loadingOptions = true;
    });
    builder.addCase(getDonationOptions.fulfilled, (state, action) => {
      state.loadingOptions = false;
      state.options = action.payload;
    });
    builder.addCase(getDonationOptions.rejected, (state, action) => {
      state.loadingOptions = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getDonationOptionsSlice.reducer;
