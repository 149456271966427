import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
  Button,
  styled,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import theme from "../../theme";
import { Avatar, Stack, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { group } from "console";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAppDispatch } from "../../redux/hooks";
import { getExpenseData } from "../../redux/slices/getExpenseDataSlice";
import { softDeleteExpense } from "../../redux/slices/expense/softDeleteExpenseByIDSlice";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Expense Data</Box>
    </StyledGridOverlay>
  );
}

const ExpenseTableBody = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { expenseData, loading } = useSelector((state: any) => state.getExpenses);

  const [data, setData] = useState<any>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState<number | null>(
    null
  ); // To hold the selected expense ID for deletion

  useEffect(() => {
    if (Array.isArray(expenseData)) {
      if (expenseData) {
        console.log("Effect Data - ", expenseData);
        let data1 = expenseData?.map((expense: any, index: any) => ({
          id: expense.sr_no,
          by: expense.by,
          date: expense.date,
          amount: expense.amount,
          purpose: expense.purpose,
          event: expense.event,
          head: expense.head,
          note: expense.note,
        }));
        setData(data1);
      }
    } else {
      setData([]); // Set an empty array if donationData is not valid
    }
  }, [expenseData]);

  const handleEdit = (id: number) => {
    navigate(`/expenses/edit/${id}`); // Navigate to the edit page
  };

  const handleDelete = (id: number) => {
    setSelectedExpenseId(id); // Set the selected expense ID
    setDeleteDialogOpen(true); // Open the confirmation dialog
  };

  const confirmDelete = () => {
    if (selectedExpenseId) {
      dispatch(softDeleteExpense({ expenseId: selectedExpenseId })); // Call the delete action
      setDeleteDialogOpen(false); // Close the dialog after deletion
      refreshData();
    }
  };

  const cancelDelete = () => {
    setSelectedExpenseId(null);
    setDeleteDialogOpen(false); // Close the dialog without deleting
  };

  const refreshData = () => {
    dispatch(getExpenseData());
  };

  const columns: any = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Sr. No.</p>
        </Stack>
      ),
    },
    {
      field: "by",
      headerName: "By",
      width: 170,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>By</p>
        </Stack>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Date</p>
        </Stack>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Amount</p>
        </Stack>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Purpose</p>
        </Stack>
      ),
    },
    {
      field: "event",
      headerName: "Event",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Event</p>
        </Stack>
      ),
    },
    {
      field: "head",
      headerName: "head",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Head</p>
        </Stack>
      ),
    },
    {
      field: "note",
      headerName: "Note",
      width: 250,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Note</p>
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 70,

      renderCell: (params: any) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEdit(params.row.id)}
          >
            <Icon icon="lucide:pencil" className="icons" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.id)}
          >
            <Icon icon="lucide:trash" className="icons" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div className="table">
      <div
        className="button"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {/* Left-aligned button */}
        <Button
          variant="outlined"
          startIcon={<Icon icon="lucide:trash" className="icons" />}
          onClick={() => navigate("/expenses/bin")} // Add your navigation logic here
        >
          Bin
        </Button>

        {/* Right-aligned buttons */}
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            startIcon={<Icon icon="lucide:refresh-cw" className="icons" />}
            onClick={refreshData}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            startIcon={<Icon icon="lucide:plus" className="icons" />}
            onClick={() => navigate("/expenses/new")}
          >
            Create
          </Button>
        </div>
      </div>

      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        <DataGridPro
          rows={data}
          columns={columns}
          loading={loading}
          autoHeight
          {...data}
          slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          checkboxSelection
          initialState={{
            pinnedColumns: { left: ["by"], right: ["actions"] },
          }}
        />
      </Box>

      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to delete this record?</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExpenseTableBody;
