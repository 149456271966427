import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";  
import axios from "axios";  
  
const API_BASE = process.env.REACT_APP_API_BASE;  
  
// Define the type for the payload including donation ID and data  
interface EditDonationPayload {  
  donationId: string;  
  donationData: any;  
}  
  
// Async thunk for editing a donation  
export const editDonation = createAsyncThunk(  
  "editDonation",  
  async ({ donationId, donationData }: EditDonationPayload) => {  
    const { data } = await axios.put(`${API_BASE}/donations/${donationId}`, donationData, {  
      withCredentials: true,  
    });  
    return data;  
  }  
);  
  
const editDonationSlice = createSlice({  
  name: "editDonation",  
  initialState: {  
    loading: false,  
    donation: null as any,  
    error: false,  
    errorData: null as any,  
  },  
  reducers: {},  
  extraReducers: (builder) => {  
    builder  
      .addCase(editDonation.pending, (state) => {  
        state.loading = true;  
      })  
      .addCase(editDonation.fulfilled, (state, action) => {  
        state.loading = false;  
        state.donation = action.payload; // Updated donation data  
      })  
      .addCase(editDonation.rejected, (state, action) => {  
        state.loading = false;  
        state.error = true;  
        state.errorData = action.error; // Store the actual error object  
      });  
  },  
});  
  
export default editDonationSlice.reducer;  
