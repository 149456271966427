// src/pages/EditExpense.tsx

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/hooks";
import { getExpenseById } from "../redux/slices/getExpenseByIDSlice"; // Action to get expense by ID
import { editExpense } from "../redux/slices/editExpenseByIDSlice"; // Action to edit expense
import { getDonationOptions } from "../redux/slices/options/getDonationOptionsSlice";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Autocomplete,
  Container,
} from "@mui/material";
import layoutTwo from "../components/layout/layoutTwo";

interface ExpenseData {
  sr_no: number;
  by: string;
  amount: string;
  date: string;
  purpose: string;
  event: string;
  head: string;
  note: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

const EditExpense = () => {
  const { id } = useParams(); // Get the expense ID from URL
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { expenseDetails, loading } = useSelector(
    (state: any) => state.getExpense
  );
  const { options, loadingOptions } = useSelector(
    (state: any) => state.getDonationOptions
  );

  const [expenseData, setExpenseData] = useState({
    sr_no: 0,
    by: "",
    amount: "",
    date: "",
    purpose: "",
    event: "",
    head: "",
    note: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [numericAmount, setNumericAmount] = useState<number | null>(null);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getExpenseById(id)); // Fetch the expense details based on ID
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getDonationOptions()); // Fetch the dropdown options
  }, []);

  useEffect(() => {
    if (expenseDetails) {
      setExpenseData(expenseDetails); // Set initial form data with fetched expense data
    }
  }, [expenseDetails]);

  const textHandler = (e: any, input: keyof typeof expenseData) => {
    const newValue = e.target.value;
    setExpenseData((prevData) => ({
      ...prevData,
      [input]: newValue,
    }));

    if (input === "amount") {
      if (/^\d+(\.\d{1,2})?$/.test(newValue.trim())) {
        setNumericAmount(parseFloat(newValue.trim()));
      } else {
        setNumericAmount(null);
      }
    }
  };

  const autoHandler = (e: any, value: any, input: keyof ExpenseData) => {
    setExpenseData((prevData) => ({
      ...prevData,
      [input]: value, // Handle Autocomplete input
    }));
  };

  const dateHandler = (e: Dayjs | null, input: keyof typeof expenseData) => {
    const selectedDate = e ? moment(e.toISOString()) : "";
    setExpenseData((prevData) => ({
      ...prevData,
      [input]: selectedDate,
    }));
  };

  const validateData = (): boolean => {
    let tempErrors: { [key: string]: string } = {};
    let isValid = true;

    if (!expenseData.by.trim()) {
      isValid = false;
      tempErrors.by = "Name is required.";
    }

    if (!expenseData.amount.trim()) {
      isValid = false;
      tempErrors.amount = "Amount is required.";
    } else if (!/^\d+(\.\d{1,2})?$/.test(expenseData.amount.trim())) {
      isValid = false;
      tempErrors.amount = "Invalid amount format.";
    } else {
      setNumericAmount(parseFloat(expenseData.amount.trim()));
    }

    if (!expenseData.date) {
      isValid = false;
      tempErrors.date = "Date is required.";
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormDataValid = validateData();

    if (isFormDataValid && id) {
      const updatedData = {
        ...expenseData,
        amount: numericAmount,
        date: expenseData.date || moment().toISOString(),
      };

      dispatch(editExpense({ expenseId: id, expenseData: updatedData })); // Dispatch the editExpense action
      navigate("/expenses");
    } else {
      console.log("Validation Errors:", errors);
    }
  };

  return (
    <div className="user-edit">
      <div role="presentation" onClick={() => navigate("/expenses")}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/expenses"
            label="Expenses"
            onClick={() => navigate("/expenses")}
          />
          <StyledBreadcrumb label="Edit" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {loading || loadingOptions ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StyledPaper variant="outlined">
            <Box
              component="form"
              sx={{ flexGrow: 1 }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
                sx={{ pb: 2 }}
              >
                <Grid xs={12} sm={12}>
                  <Typography variant="h4" gutterBottom>
                    Edit Expense
                  </Typography>
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Sr. No."
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    disabled
                    value={expenseData.sr_no}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
              >
                <Grid xs={12} sm={6}>
                  <TextField
                    label="By"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors.by}
                    helperText={errors.by}
                    value={expenseData.by}
                    onChange={(e) => textHandler(e, "by")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={expenseData.date ? dayjs(expenseData.date) : null}
                      label="Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          required: true,
                          error: !!errors.date,
                          helperText: errors.date,
                        },
                      }}
                      onChange={(e) => dateHandler(e, "date")}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Amount"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    value={expenseData.amount}
                    error={!!errors.amount}
                    helperText={errors.amount}
                    onChange={(e) => textHandler(e, "amount")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="purpose-autocomplete"
                    options={options?.expense_purpose.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={expenseData.purpose}
                    onChange={(e, value) => autoHandler(e, value, "purpose")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purpose"
                        size="small"
                        error={!!errors.purpose}
                        helperText={errors.purpose}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="event-autocomplete"
                    options={options?.events.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={expenseData.event}
                    onChange={(e, value) => autoHandler(e, value, "event")}
                    renderInput={(params) => (
                      <TextField {...params} label="Event" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="head-autocomplete"
                    options={options?.heads.options || []}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    value={expenseData.head}
                    onChange={(e, value) => autoHandler(e, value, "head")}
                    renderInput={(params) => (
                      <TextField {...params} label="Head" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Note"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={expenseData.note}
                    error={!!errors.note}
                    helperText={errors.note}
                    onChange={(e) => textHandler(e, "note")}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: 200, mt: 2 }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}
      </Container>
    </div>
  );
};

export default layoutTwo(EditExpense);
